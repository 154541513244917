import moment from "moment";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

function UserExportPdf({pdfData}){
    const conponentPDF = useRef();


    // console.log(pdfData)

// useEffect(()=>{

// },[pdfData]);

        const generatePDF= useReactToPrint({ 

            content: ()=>conponentPDF.current,
            documentTitle:"Transactions pdf",
            onAfterPrint:()=>alert("Data saved in PDF")
        });


        
    

    return (
        <>
         <div style={{ position: 'absolute', left: '-9999px', top: '0' }}>
            <div ref={conponentPDF} style={{width:'80%'}}>
                <h2 class="text-center border-bottom"><u>Liste des Utilisateurss</u></h2>
                <table class="table table-striped"  style={{"text-align": "center", height:"20px", paddingTop: 0, paddingBottom: "-5px"}}>
                    <thead className="bg-light">
                    <tr>
                                    <th>
                                    Date
                                    </th>
                                    <th>NOM</th>
                                    <th>PRENOM</th>
                                    <th >ID-SITE</th>
                                    <th>ENTREPRISE</th>
                                    <th>STATUT</th>
                                </tr>
                    </thead>
                    <tbody>
                
                        {
                            pdfData?.map((marchand,index)=>
                            <tr>
                            <td>{moment(marchand.created_at).format("Do MMMM YYYY")}</td>
                            <td>{marchand.nom}</td>
                            <td>{marchand.prenom}</td>
                            <td>{marchand.siteID}</td>
                            <td>{marchand.entreprise}</td>
                            <td>
                                {marchand.is_active == 1 && <span class="badge bg-success">active</span>}
                                {marchand.is_active == 0 && <span class="badge bg-danger">desactive</span>}
                            </td>
                           
                        </tr>
                            )
                        }
                    
                    </tbody>
                </table>
            </div>
        </div>

        <button type="button" class="btn btn-danger float-end mx-1" onClick={generatePDF}>
        <i class="bi bi-star me-1"></i> Export PDF
    </button>
        </>

    )
}

export default UserExportPdf;